import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@fortawesome/fontawesome-free/css/all.css'; // Ensure your project is capable of handling css
import { aliases, mdi } from 'vuetify/iconsets/mdi';

// eslint-disable-next-line no-undef
export default defineNuxtPlugin((app) => {
  const myCustomLightTheme = {
    dark: false,
    colors: {
      background: '#FFFFFF',
      surface: '#FFFFFF',
      primary: '#61aaa7',
      secondary: '#756f86',
      'secondary-darken-1': '#018786',
      error: '#b71c1c',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FB8C00',
    },
  };
  const vuetify = createVuetify({
    defaults: {
      VBtn: {
        color: 'primary',
        rounded: 'md',
      },
      VCard: {
        rounded: 'lg',
      },
      VRadioGroup:{
        color:'#61aaa7'
      }
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        myCustomLightTheme,
      },
    },
    ssr: true,
    components,
    directives,
    rtl: false,
    display: {
      mobileBreakpoint: 'sm',
      thresholds: {
        xs: 0,
        sm: 400,
        md: 651,
        lg: 961,
        xl: 1600,
      },
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      },
    },
  });

  app.vueApp.use(vuetify);
});
