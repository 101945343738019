import { defineNuxtPlugin } from '#app';
import { useAuthStore } from '@/stores/AuthStore';
import { useSessionStore } from '@/stores/SessionStore';

export default defineNuxtPlugin(async (nuxtApp) => {
  if (process.client) {
    const route = useRoute();
    const { getValue } = useLocalStorage();
    const token = getValue('token');
    const authStore = useAuthStore(nuxtApp.$pinia);
    const sessionStore = useSessionStore(nuxtApp.$pinia);
    if (!authStore.auth.isLoggedIn && token && token.length > 0) {
      authStore.setHeaders(token);
      if(route.name !== 'room'){
       await sessionStore.getCurrentSessionId()
      }
      if(!authStore.auth.user){
        await authStore.getProfile();
      }    
    }
  }
});
