export default defineNuxtRouteMiddleware((to) => {
  if (process.client) {
    const { getValue } = useLocalStorage();
    const token = getValue('token')
    if (to.name.includes('auth-') && token && token.length > 0) {
      to.name = 'index';
      return navigateTo('/');
    }
  }
});
