import { useAuthStore } from '~~/stores/AuthStore';
export default function useCheckRequestStatusCode() {
  function checkStatusCode(statusCode) {
    const authStore = useAuthStore();
    
    if (statusCode === 401) {
      const { removeValue } = useLocalStorage();
      removeValue('token');
      delete authStore.auth.headers.Authorization;
      authStore.auth.user = {};
      navigateTo({ path: '/auth/signin' });
    }
  }
  return { checkStatusCode };
}
